import React from "react";
import { Dark } from "../../../../Theme/Theme";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
export default function Menu(props) {
  return (
    <div
      style={{
        display: "flex",
        height: 45,
        backgroundColor: Dark.primary,
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>MASTER</div>
        <div onClick={() => props.sort("MasterUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("MasterDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>CLIENT</div>
        <div onClick={() => props.sort("ClientUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("ClientDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>SYMBOL</div>
        <div onClick={() => props.sort("SymbolUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("SymbolDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>QTY</div>
        <div onClick={() => props.sort("QtyUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("QtyDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>BUY/SELL</div>
        <div onClick={() => props.sort("BuySellUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("BuySellDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>RATE</div>
        <div onClick={() => props.sort("RateUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("RateDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>TYPE</div>
        <div onClick={() => props.sort("TypeUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("TypeDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>P/L</div>
        <div onClick={() => props.sort("PlUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("PlDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>DATE</div>
        <div onClick={() => props.sort("DateUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("DateDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
    </div>
  );
}
