import React from "react";
import { Dark } from "../../../Theme/Theme";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
export default function Menu(props) {
  return (
    <div
      style={{
        height: 30,
        display: "flex",
        backgroundColor: Dark.primary,
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
        fontSize:14
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Master</div>
        <div onClick={() => props.sort("MasterUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("MasterDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Client</div>
        <div onClick={() => props.sort("ClientUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("ClientDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Symbol</div>
        <div onClick={() => props.sort("SymbolUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("SymbolDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div> Buy-Sell</div>
        <div onClick={() => props.sort("BuySellUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div
          onClick={() => props.sort("BuySellDown")}
          style={{ paddingLeft: 3 }}
        >
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Net-Qty</div>
        <div onClick={() => props.sort("QtyUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("QtyDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Rate</div>
        <div onClick={() => props.sort("RateUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("RateDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Limit-Type</div>
        <div onClick={() => props.sort("LimitUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("LimitDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>

      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>Type</div>
        <div onClick={() => props.sort("TypeUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("TypeDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>MSG</div>
        <div onClick={() => props.sort("MsgUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("MsgDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>DATE</div>
        <div onClick={() => props.sort("DateUp")} style={{ paddingLeft: 3 }}>
          <FaArrowUp style={{ fontSize: 10 }} />
        </div>
        <div onClick={() => props.sort("DateDown")} style={{ paddingLeft: 3 }}>
          <FaArrowDown style={{ fontSize: 10 }} />
        </div>
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>
    </div>
  );
}
