import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import { BsFilePdf } from "react-icons/bs";
import Master from "./Master/Master";
import Client from "./Client/Client";
import Symbol from "./Symbol/Symbol";
import Filter from "./Filter/Filter";
import TradePdf from "./TradePdf/TradePdf";
import moment from "moment";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { NumericFormat } from "react-number-format";

export default function TopCard(props) {
  const [master, setMaster] = useState(false);
  const [client, setClient] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [filter, setFilter] = useState(false);

  const [filtertype, setFilterType] = useState("");

  const [master_data, setMasterData] = useState("");
  const [client_data, setClientData] = useState("");
  const [symbol_data, setSymbolData] = useState("");

  const clearclient = () => {
    setClientData("");
    props.client("");
    props.reload();
  };
  const clearmaster = () => {
    setMasterData("");
    props.master("");
    props.reload();
  };
  const clearsymbol = () => {
    setSymbolData("");
    props.symbol("");
    props.reload();
  };
  const clearfilter = () => {
    setFilterType("");
    props.filter("");
    props.reload();
  };

  var buy_volume = parseFloat(
    props.buy_volume == null ? 0 : props.buy_volume
  ).toFixed(0);
  var sell_volume = parseFloat(
    props.sell_volume == null ? 0 : props.sell_volume
  ).toFixed(0);
  var profit_loss = parseFloat(props.profit_loss).toFixed(0);
  var brokerage = parseFloat(props.brokerage).toFixed(0);
  let m2m = 0;

  m2m = parseFloat(parseFloat(profit_loss) + parseFloat(brokerage)).toFixed(0);

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: Dark.background,
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Profit/Loss</div>
          <div
            style={{
              color: profit_loss > 0 ? Dark.buy : Dark.sell,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            <NumericFormat
              value={profit_loss}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>M2M</div>
          <div
            style={{
              color: m2m > 0 ? Dark.buy : Dark.sell,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            <NumericFormat
              value={m2m}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>BROKERAGE</div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            <NumericFormat
              value={brokerage}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>BUY VOLUME</div>
          <div
            style={{
              color: Dark.buy,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            <NumericFormat
              value={buy_volume}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>SELL VOLUME</div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            <NumericFormat
              value={sell_volume}
              displayType={"text"}
              thousandSeparator={true}
            />{" "}
            ₹
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div onClick={clearmaster} style={{ color: master_data == "" ? Dark.text : Dark.sell, fontSize: 14 }}>
            {master_data == "" ? "MASTER" : "CLEAR"}
          </div>
          <div
            onClick={() => setMaster(true)}
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {master_data == "" ? "SEARCH" : master_data.username}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div onClick={clearclient} style={{ color: client_data == "" ? Dark.text : Dark.sell, fontSize: 14 }}>
            {client_data == "" ? "CLIENT" : "CLEAR"}
          </div>
          <div
            onClick={() => setClient(true)}
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {client_data == "" ? "SEARCH" : client_data.username}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div onClick={clearsymbol} style={{ color: symbol_data == "" ? Dark.text : Dark.sell, fontSize: 14 }}>
            {symbol_data == "" ? "SYMBOL" : "CLEAR"}
          </div>
          <div
            onClick={() => setSymbol(true)}
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {symbol_data == "" ? "SEARCH" : symbol_data}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
            textAlign: "center",
          }}
        >
          <div onClick={clearfilter} style={{ color: filtertype == "" ? Dark.text : Dark.sell, fontSize: 14 }}>
            {filtertype == "" ? "FILTER" : "CLEAR"}
          </div>
          <div
            onClick={() => setFilter(true)}
            style={{
              color: Dark.text,
              marginTop: 5,
              fontSize: 16,
            }}
          >
            {filtertype == "" || filtertype == "CUSTOM" ? (
              <>
                <div style={{ marginBottom: 3, fontSize: 12 }}>
                  START : {moment(props.start).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontSize: 12 }}>
                  END :{moment(props.end).format("DD-MM-YYYY")}
                </div>
              </>
            ) : (
              filtertype
            )}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "50px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              color: Dark.text,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={
                <TradePdf
                  buy_volume={props.buy_volume}
                  sell_volume={props.sell_volume}
                  trade={props.trade}
                  profit_loss={profit_loss}
                  brokerage={brokerage}
                  m2m={m2m}
                />
              }
              fileName={"Trade"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>
                  <BsFilePdf size={30} color={Dark.text} />
                </div>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </div>

      <Dialog
        header={"Master List"}
        visible={master}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setMaster(false)}
      >
        <Master
          symbol_id={props.symbol_id}
          client_id={props.client_id}
          master={props.master}
          start={props.start}
          end={props.end}
          updatemaster={(a) => setMasterData(a)}
          close={() => setMaster(false)}
        />
      </Dialog>

      <Dialog
        header={"Client List"}
        visible={client}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setClient(false)}
      >
        <Client
          symbol_id={props.symbol_id}
          master_id={props.master_id}
          client={props.client}
          start={props.start}
          end={props.end}
          updateclient={(a) => setClientData(a)}
          close={() => setClient(false)}
        />
      </Dialog>

      <Dialog
        header={"Symbol List"}
        visible={symbol}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setSymbol(false)}
      >
        <Symbol
          symbol={props.symbol}
          master_id={props.master_id}
          client_id={props.client_id}
          start={props.start}
          end={props.end}
          updatesymbol={(a) => setSymbolData(a)}
          close={() => setSymbol(false)}
        />
      </Dialog>

      <Dialog
        header={"Select Filter"}
        visible={filter}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setFilter(false)}
      >
        <Filter
          start={props.start}
          end={props.end}
          filter={props.filter}
          updatefilter={(a) => {
            setFilterType(a);
            setFilter(false);
          }}
        />
      </Dialog>
    </div>
  );
}
