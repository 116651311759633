import React from "react";
import { Dark } from "../../../../../../Theme/Theme";

export default function MasterCard(props) {
  const i = props.item;
  return (
    <div
      onClick={() => props.updateMaster(i)}
      style={{
        margin: 10,
        padding: 10,
        backgroundColor: props.id == i.master ? Dark.primary : Dark.secondary,
        width: "95%",
        display: "flex",
        alignItems: "center",
        borderRadius: 10,
        marginBottom: 10,
      }}
    >
      {i.username} ({i.name})
    </div>
  );
}
