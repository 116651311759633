import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import TopCard from "./TopCard/TopCard";
import Menu from "./Menu";
import PositionCard from "./PositionCard/PositionCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import LivePl from "../../../Healer/LivePl";
import { useDispatch } from "react-redux";
import Trade from "./Trade/Trade";
import { Dialog } from "primereact/dialog";
import Loading from "react-loading";
import Total from "./Total/Total";
import useSize from "../../../Utils/useSize";

const backend = new Backend();

export default function Position() {
  const windowSize = useSize();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState(false);
  const [standing, setStanding] = useState([]);
  const [standingsearch, setStandingSearch] = useState([]);
  const [booked, setBooked] = useState(0);
  const [master_id, setMasterId] = useState("");
  const [client_id, setClientId] = useState("");
  const [symbol, setSymbol] = useState("");

  const [name, setName] = useState("");
  const [trade, setTrade] = useState([]);

  const [show, setShow] = useState(false);

  useEffect(() => {
    loadStandingTrade();
  }, []);

  const loadStandingTrade = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_standing_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });

        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadMasterStanding = (a) => {
    setMasterId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      master_id: a,
      client_id: client_id,
      symbol: symbol,
    };
    backend.load_master_standing_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadClientStanding = (a) => {
    setClientId(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      master_id: master_id,
      client_id: a,
      symbol: symbol,
    };
    backend.load_client_standing_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadSymbolStanding = (a) => {
    setSymbol(a);
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      master_id: master_id,
      client_id: client_id,
      symbol: a,
    };
    backend.load_symbol_standing_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        dispatch({
          type: "CLEAR_PF",
        });
        setStanding(r.standing);
        setStandingSearch(r.standing);
        setBooked(r.booked);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const Update = (a) => {
    dispatch({
      type: "CLEAR_PF",
    });
    setStanding(a);
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "MasterUp") {
      data = standingsearch.sort((a, b) =>
        a.master_username > b.master_username ? 1 : -1
      );
    } else if (s == "MasterDown") {
      data = standingsearch.sort((a, b) =>
        a.master_username < b.master_username ? 1 : -1
      );
    } else if (s == "ClientUp") {
      data = standingsearch.sort((a, b) =>
        a.client_username > b.client_username ? 1 : -1
      );
    } else if (s == "ClientDown") {
      data = standingsearch.sort((a, b) =>
        a.client_username < b.client_username ? 1 : -1
      );
    } else if (s == "SymbolUp") {
      data = standingsearch.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
    } else if (s == "SymbolDown") {
      data = standingsearch.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
    } else if (s == "BuySellUp") {
      data = standingsearch.sort((a, b) => (a.buy_sell > b.buy_sell ? 1 : -1));
    } else if (s == "BuySellDown") {
      data = standingsearch.sort((a, b) => (a.buy_sell < b.buy_sell ? 1 : -1));
    } else if (s == "QtyUp") {
      data = standingsearch.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    } else if (s == "QtyDown") {
      data = standingsearch.sort((a, b) => (a.qty < b.qty ? 1 : -1));
    } else if (s == "RateUp") {
      data = standingsearch.sort((a, b) => (a.rate > b.rate ? 1 : -1));
    } else if (s == "RateDown") {
      data = standingsearch.sort((a, b) => (a.rate < b.rate ? 1 : -1));
    }

    setStanding(data);
  };

  return (
    <div
      key={key}
      style={{
        display: "flex",
        height: windowSize[1] - 130,
        width: windowSize[0],
        flexDirection: "column",
      }}
    >
      {standing.length > 0
        ? standing.map((i) => {
            return <LivePl item={i} key={i.id} total={standing.length} />;
          })
        : null}
      <div>
        <TopCard
          booked={booked}
          master_id={master_id}
          client_id={client_id}
          symbol_id={symbol}
          reload={loadStandingTrade}
          master={loadMasterStanding}
          client={loadClientStanding}
          symbol={loadSymbolStanding}
        />
      </div>
      <div style={{ backgroundColor: Dark.background }}>
        <Total standing={standingsearch} Update={Update} />
        <Menu sort={sort} />
        <div className="scroll" style={{ overflowY: "scroll", height: windowSize[1] - 290, }}>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Loading type="spin" color={Dark.text} height={35} width={35} />
            </div>
          ) : standing.length > 0 ? (
            standing.map((i, t) => {
              return (
                <PositionCard
                  item={i}
                  key={i.id}
                  show={() => setShow(true)}
                  name={(a, b) => setName("ALL SAUDA OF " + a + ` (${b}) `)}
                  trade={(a) => setTrade(a)}
                />
              );
            })
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                height: "100%",
                textTransform: "uppercase",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Active Posistion Found.</div>
            </div>
          )}
        </div>
      </div>

      <Dialog
        header={name}
        visible={show}
        className="modalpo"
        style={{ width: "60vw", height: "70vh" }}
        onHide={() => setShow(false)}
      >
        <Trade trade={trade} />
      </Dialog>
    </div>
  );
}
