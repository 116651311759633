import React, { useState } from "react";
import MasterList from "./MasterList/MasterList";
import Masters from "./Master/Master";
import AddMaster from "./AddMaster/AddMaster";
import { Dark } from "../../../Theme/Theme";
import Summary from "./Summary/Summary";
import useSize from "../../../Utils/useSize";

export default function Master() {
  const windowSize = useSize();
  const [type, setType] = useState(0);
  const [master, setMaster] = useState("");
  const [update, setupdate] = useState(0);

  const AddNewMaster = () => {
    setType(1);
  };
  const ShowSummary = () => {
    setType(0);
  };

  return (
    <div
      style={{
        height: windowSize[1] - 130,
        width: windowSize[0],
        display: "flex",
      }}
    >
      <div style={{ flex: 3, margin: 10 }}>
        {type == 0 ? <Summary /> : null}
        {type == 2 ? (
          <MasterList
            master={master}
            reload={() => {
              setupdate(1);
            }}
          />
        ) : null}
        {type == 1 ? (
          <AddMaster
            reload={() => {
              setupdate(1);
              ShowSummary();
            }}
          />
        ) : null}
      </div>
      <div key={update} style={{ flex: 1, backgroundColor: Dark.background }}>
        <Masters
          Add={AddNewMaster}
          ShowSummary={ShowSummary}
          setMaster={(a) => {
            setMaster(a);
            setType(2);
          }}
          key={update}
          master={master}
        />
      </div>
    </div>
  );
}
