import React, { useEffect, useState } from "react";
import { Dark, Light } from "../../../../Theme/Theme";
import LiveTrade from "./LiveTrade";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import { useSelector } from "react-redux";
import LivePl from "../../../../Healer/LivePl";
import { MdOutlineNotificationsActive } from "react-icons/md";
import { NumericFormat } from "react-number-format";
import RightPl from "./RightPl";

const backend = new Backend();

export default function RightCard(props) {
  const theme = useSelector((state) => state.theme);

  const [key, setKey] = useState(false);

  const [loading, setLoading] = useState(false);
  const [trade, setTrade] = useState(0);
  const [tradesearch, setTradeSearch] = useState(0);
  const [client, setClient] = useState(0);
  const [broker, setBroker] = useState(0);
  const [booked, setBooked] = useState(0);
  const [standing, setStanding] = useState([]);

  useEffect(() => {
    loadLeftCard();
    loadLiveTrade();
    const interval = setInterval(() => {
      loadLeftCard();
      loadLiveTrade();
    }, 30000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, []);

  const loadLiveTrade = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      server: localStorage.getItem("server"),
      username: localStorage.getItem("username"),
    };

    backend.live_trade(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setTrade(r.trade);
        setTradeSearch(r.trade);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const loadLeftCard = () => {
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    console.log(data, "bybvy");

    backend.load_all_data(data).then((r) => {
      if (r.error == "False") {
        setClient(r.client);
        setBroker(r.broker);
        setStanding(r.standing);
        setBooked(r.profit_loss);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "MasterUp") {
      data = tradesearch.sort((a, b) =>
        a.master_username > b.master_username ? 1 : -1
      );
    } else if (s == "MasterDown") {
      data = tradesearch.sort((a, b) =>
        a.master_username < b.master_username ? 1 : -1
      );
    } else if (s == "ClientUp") {
      data = tradesearch.sort((a, b) =>
        a.client_username > b.client_username ? 1 : -1
      );
    } else if (s == "ClientDown") {
      data = tradesearch.sort((a, b) =>
        a.client_username < b.client_username ? 1 : -1
      );
    } else if (s == "SymbolUp") {
      data = tradesearch.sort((a, b) => (a.symbol > b.symbol ? 1 : -1));
    } else if (s == "SymbolDown") {
      data = tradesearch.sort((a, b) => (a.symbol < b.symbol ? 1 : -1));
    } else if (s == "BuySellUp") {
      data = tradesearch.sort((a, b) => (a.buy_sell > b.buy_sell ? 1 : -1));
    } else if (s == "BuySellDown") {
      data = tradesearch.sort((a, b) => (a.buy_sell < b.buy_sell ? 1 : -1));
    } else if (s == "QtyUp") {
      data = tradesearch.sort((a, b) => (a.qty > b.qty ? 1 : -1));
    } else if (s == "QtyDown") {
      data = tradesearch.sort((a, b) => (a.qty < b.qty ? 1 : -1));
    } else if (s == "RateUp") {
      data = tradesearch.sort((a, b) => (a.rate > b.rate ? 1 : -1));
    } else if (s == "RateDown") {
      data = tradesearch.sort((a, b) => (a.rate < b.rate ? 1 : -1));
    } else if (s == "TypeUp") {
      data = tradesearch.sort((a, b) => (a.type > b.type ? 1 : -1));
    } else if (s == "TypeDown") {
      data = tradesearch.sort((a, b) => (a.type < b.type ? 1 : -1));
    } else if (s == "PlUp") {
      data = tradesearch.sort((a, b) =>
        a.profit_loss > b.profit_loss ? 1 : -1
      );
    } else if (s == "PlDown") {
      data = tradesearch.sort((a, b) =>
        a.profit_loss < b.profit_loss ? 1 : -1
      );
    } else if (s == "DateUp") {
      data = tradesearch.sort((a, b) =>
        a.date_created > b.date_created ? 1 : -1
      );
    } else if (s == "DateDown") {
      data = tradesearch.sort((a, b) =>
        a.date_created < b.date_created ? 1 : -1
      );
    } else {
      setTrade([]);
    }

    setTrade(data);
  };

  return (
    <div>
      <div key={standing.length}>
        {standing.length > 0
          ? standing.map((i) => {
              return <LivePl item={i} key={i.id} total={standing.length} />;
            })
          : null}
      </div>

      <div
        style={{
          height: 60,
          color: Dark.text,
          fontSize: 22,
          backgroundColor: Dark.primary,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingLeft: 15,
          paddingRight: 15,
        }}
      >
        <div
          style={{
            flex: 1,
            textTransform: "uppercase",
            fontSize: 16,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RightPl standing={standing} />

          <div
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              color: Dark.text,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              backgroundColor: theme == 0 ? Dark.background : Light.background,
              width: 120,
              height: 50,
              marginLeft: 10,
            }}
          >
            <div style={{ fontSize: 11 }}> BOOKED P&L </div>
            <div
              style={{ color: booked > 0 ? Dark.buy : Dark.sell, fontSize: 12 }}
            >
              <NumericFormat
                value={booked.toFixed(0)}
                displayType={"text"}
                thousandSeparator={true}
              />{" "}
              ₹
            </div>
          </div>

          <div
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              color: Dark.text,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              backgroundColor: theme == 0 ? Dark.background : Light.background,
              width: 100,
              height: 50,
              marginLeft: 10,
            }}
          >
            <div style={{ fontSize: 12 }}> CLIENT </div>
            <div
              style={{
                color: theme > 0 ? Dark.text : Light.background,
                fontSize: 12,
              }}
            >
              {parseFloat(client).toFixed(0)}
            </div>
          </div>
          <div
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              color: Dark.text,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              backgroundColor: theme == 0 ? Dark.background : Light.background,
              width: 100,
              height: 50,
              marginLeft: 10,
            }}
          >
            <div style={{ fontSize: 12 }}> BROKER </div>
            <div
              style={{
                color: theme > 0 ? Dark.text : Light.background,
                fontSize: 12,
              }}
            >
              {parseFloat(broker).toFixed(0)}
            </div>
          </div>
        </div>

        <div
          onClick={() => (props.status ? props.hide() : props.show())}
          style={{
            width: 50,
            backgroundColor: props.status ? Dark.sell : Dark.buy,
            height: 50,
            borderRadius: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginLeft: 10,
            flexDirection: "column",
          }}
        >
          <MdOutlineNotificationsActive color={Dark.text} fontSize={25} />

          <div style={{ fontSize: 10, paddingTop: 3 }}>
            {" "}
            {props.status ? "HIDE" : "SHOW"}
          </div>
        </div>
      </div>
      <div key={key}>
        <LiveTrade sort={sort} trade={trade} key={trade} loading={loading} />
      </div>
    </div>
  );
}
