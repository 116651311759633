import React, { useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Menu from "./Menu";
import PositionCard from "./PositionCard/PositionCard";
import Backend from "../../../Backend/Backend";
import { toast } from "react-toast";
import Loading from "react-loading";
import useSize from "../../../Utils/useSize";

const backend = new Backend();

export default function PositionMaster() {
  const windowSize = useSize();
  const [loading, setLoading] = useState(false);
  const [master, setMaster] = useState([]);
  const [mastersearch, setMasterSearch] = useState([]);
  const [update, setUpdate] = useState(false);

  const [key, setKey] = useState(false);

  useEffect(() => {
    loadStanding();
  }, []);

  const loadStanding = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_standing_master(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setMaster(r.master);
        setMasterSearch(r.master);
        setUpdate(!update);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const sort = (s) => {
    setKey(!key);

    let data;

    if (s == "MasterUp") {
      data = mastersearch.sort((a, b) =>
        a.master_username > b.master_username ? 1 : -1
      );
    } else if (s == "MasterDown") {
      data = mastersearch.sort((a, b) =>
        a.master_username < b.master_username ? 1 : -1
      );
    } else if (s == "BookedUp") {
      data = mastersearch.sort((a, b) =>
        a.profit_loss_week > b.profit_loss_week ? 1 : -1
      );
    } else if (s == "BookedDown") {
      data = mastersearch.sort((a, b) =>
        a.profit_loss_week < b.profit_loss_week ? 1 : -1
      );
    } else {
      setMaster([]);
    }

    setMaster(data);
  };

  return (
    <div
      style={{
        display: "flex",
        height: windowSize[1] - 130,
        width: windowSize[0],
        flexDirection: "column",
      }}
    >
      <div style={{ backgroundColor: Dark.background }}>
        <Menu sort={sort} />
        <div
          className="scroll"
          style={{ height: windowSize[1] - 150, overflowY: "scroll" }}
        >
          {loading ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading type="spin" height={35} width={35} color={Dark.text} />
            </div>
          ) : master.length > 0 ? (
            master.map((i, t) => {
              return <PositionCard item={i} load={loadStanding} />;
            })
          ) : (
            <div
              style={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                textTransform: "uppercase",
              }}
            >
              <div style={{ fontSize: 25, marginBottom: 10, color: Dark.text }}>
                {" "}
                Nothing here
              </div>
              <div style={{ color: Dark.text }}>No Active Posistion Found.</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
