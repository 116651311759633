import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dark } from "../../../../Theme/Theme";
import { RiFileList3Line } from "react-icons/ri";
import { IoMdAddCircle } from "react-icons/io";
import { BiFilterAlt } from "react-icons/bi";
import { BsFilePdf } from "react-icons/bs";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import ClientCard from "./ClientCard";
import "./Client.css";
import Loading from "react-loading";
import { PDFDownloadLink } from "@react-pdf/renderer";
import ClientPdf from "./ClientPdf/ClientPdf";

const backend = new Backend();

export default function Client(props) {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [clientlist, setClientList] = useState([]);
  const [clientsearch, setClientSearch] = useState([]);

  const [keys, setkey] = useState(false);

  useEffect(() => {
    loadClient();
  }, [keys]);

  const loadClient = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientList(r.client);
        setClientSearch(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  // const loadPermission = () => {
  //   let data = {
  //     token: localStorage.getItem("token"),
  //     id: localStorage.getItem("id"),
  //     username: localStorage.getItem("username"),
  //     server: localStorage.getItem("server"),
  //   };

  //   backend.permission(data).then((r) => {
  //     if (r.error == "False") {
  //       setPermission(r.permission);
  //     } else {
  //       toast.error(r.message, {
  //         backgroundColor: Dark.buy,
  //         color: Dark.text,
  //       });
  //     }
  //   });
  // };

  const searchSymbol = (a) => {
    if (a.length > 1) {
      var data = clientsearch.filter((obj) =>
        Object.keys(obj).some((key) =>
          obj[key].toString().toLowerCase().includes(a.toString().toLowerCase())
        )
      );
      setClientList(data);
    } else {
      setClientList(clientsearch);
    }
  };

  const stort = (a) => {
    if (clientlist.length > 0) {
      if (a == "1") {
        let data = clientsearch.sort(
          (a, b) => parseFloat(a.status) - parseFloat(b.status)
        );
        setClientList(data);
      } else if (a == "2") {
        let data = clientsearch.sort((a, b) =>
          a.username.localeCompare(b.username)
        );
        setClientList(data);
      } else if (a == "3") {
        let data = clientsearch.sort((a, b) =>
          b.username.localeCompare(a.username)
        );
        setClientList(data);
      } else if (a == "4") {
        let data = clientsearch.sort(
          (a, b) =>
            parseFloat(a.deposit_current) - parseFloat(b.deposit_current)
        );
        setClientList(data);
      } else {
        toast.error("No Options Found", {
          backgroundColor: Dark.primary,
          color: Dark.text,
        });
      }
    }
  };

  return (
    <div>
      <div
        style={{
          height: 50,
          backgroundColor: Dark.secondary,
          marginRight: 10,
          marginLeft: 10,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <div
          onClick={props.ShowSummary}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <RiFileList3Line size={27} color={Dark.text} />
        </div>
        <div style={{ flex: 1, padding: 10 }}>
          <InputText
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
              searchSymbol(e.target.value);
            }}
            style={{
              height: 40,
              backgroundColor: Dark.background,
              background: Dark.background,
              borderStyle: "none",
              width: "100%",
              color: Dark.text,
            }}
            placeholder="Search Client,Username"
          />
        </div>
        <div
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <div class="dropdownfilter">
            <button class="dropbtnfilter">
              <BiFilterAlt size={30} color={Dark.primary} />
            </button>
            <div class="dropdown-contentfilter">
              <div
                className="link"
                onClick={() => {
                  stort("1");
                  setkey(!keys);
                }}
              >
                ACTIVE
              </div>
              <div
                className="link"
                onClick={() => {
                  stort("2");
                  setkey(!keys);
                }}
              >
                A-Z
              </div>
              <div
                className="link"
                onClick={() => {
                  stort("3");
                  setkey(!keys);
                }}
              >
                Z-A
              </div>
              <div
                className="link"
                onClick={() => {
                  stort("4");
                  setkey(!keys);
                }}
              >
                BALANCE
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={props.Add}
          style={{
            height: 50,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: 50,
          }}
        >
          <IoMdAddCircle size={35} color={Dark.buy} />
        </div>

        {clientlist.length > 0 ? (
          <div
            style={{
              height: 50,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 50,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={<ClientPdf client={clientlist} />}
              fileName={"Client-List"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>
                  <BsFilePdf size={30} color={Dark.text} />
                </div>
              )}
            </PDFDownloadLink>
          </div>
        ) : null}
      </div>

      <div
        key={keys}
        className="scroll"
        style={{
          margin: 10,
          height: "69vh",
          overflowY: "scroll",
        }}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Loading type="spin" color={Dark.text} height={35} width={35} />
          </div>
        ) : clientlist.length > 0 ? (
          <div>
            {clientlist.map((i) => {
              return (
                <ClientCard
                  item={i}
                  client={props.client}
                  setClient={(a) => props.setClient(a)}
                />
              );
            })}
          </div>
        ) : (
          <div
            style={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: Dark.text,
            }}
          >
            NO CLIENT FOUND.
          </div>
        )}
      </div>
    </div>
  );
}
