import { Font, Text, View, StyleSheet } from "@react-pdf/renderer";

Font.register({
  family: "Times-Bold",
  fontWeight: "bold",
});

const styles = StyleSheet.create({
  Family: {
    fontFamily: "Times-Bold",
    textTransform:"uppercase"
  },

  header: {
    textAlign: "center",
    marginBottom: 20,
    // backgroundColor: 'yellow', // Change header background color
    flexDirection: "column", // Arrange content horizontally
  },
  headingText1: {
    borderBottom: "2px",
    borderBottomColor: "black",
  },
  subHeading: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
    marginBottom: "3px",
  },
  subHeading2: {
    marginTop: "3px",
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
  },
  TagContainer: {
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "10px",
    width: "100%",
    marginTop: "5px",
    borderBottom: "2px",
    borderBottomColor: "black",
  },
  HeaderTagLeft: {
    flexDirection: "row",
    justifyContent: "space-between",
    fontSize: "10px",
    width: "100%",
    marginBottom: "5px",
    textDecoration: "underline",
  },
  HeaderTagRight: {
    flexDirection: "row",
    justifyContent: "space-around",
    fontSize: "10px",
    width: "45%",
    textDecoration: "underline",
  },
});

// ------ JSX SECTION ---------
const Header = () => (
  <View style={styles.header}>
    <View style={styles.TagContainer}>
      {/* ---- left side ------ */}
      <View style={[styles.HeaderTagLeft, styles.Family]}>
        <Text style={{ width: "14.2%" }}>Client</Text>
        <Text style={{ width: "14.2%" }}>Profit/Loss</Text>
        <Text style={{ width: "14.2%" }}>Brokerage</Text>
        <Text style={{ width: "14.2%" }}>B-Return</Text>
        <Text style={{ width: "14.2%" }}>B-Sharing(%)</Text>
        <Text style={{ width: "14.2%" }}>P/F Bhag(%)</Text>
        <Text style={{ width: "14.2%" }}>Net Amount</Text>
      </View>
    </View>
  </View>
);

export default Header;
