import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import SocketContext from "../../../../Healer/SocketContect";
import Backend from "../../../../Backend/Backend";
import { toast } from "react-toast";
import TPassword from "../../../../Healer/TPassword/TPassword";
import { Dialog } from "primereact/dialog";
import moment from "moment";
const backend = new Backend();

export default function PosistionCard(props) {
  const item = props.item;
  const socket = useContext(SocketContext);

  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [pf, setpf] = useState(0);
  const [btotal, setBtotal] = useState(0);

  const [tpassword, setTPassword] = useState("");

  const [showsq, setShowSq] = useState(false);

  const sockettrade = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
        if (item.buy_sell == 0) {
          let Profitloss = 0;
          let brokerage_total = 0;
          if (msg.Bid > 0) {
            Profitloss =
              parseFloat(parseFloat(msg.Bid) - parseFloat(item.rate)) *
              parseFloat(item.qty);
            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss = 0;
          }
          setpf(Profitloss - brokerage_total);
          setBtotal(brokerage_total);
        }
      } else {
        setbid(msg.LTP);
        if (item.buy_sell == 0) {
          let Profitloss = 0;
          let brokerage_total = 0;

          if (msg.LTP > 0) {
            Profitloss =
              parseFloat(parseFloat(msg.LTP) - parseFloat(item.rate)) *
              parseFloat(item.qty);

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss = 0;
          }

          setpf(Profitloss - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (item.buy_sell == 1) {
          let Profitloss2 = 0;
          let brokerage_total = 0;
          if (parseFloat(msg.Ask) > 0) {
            Profitloss2 = (item.rate - msg.Ask) * item.qty;
            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss2 = 0;
          }

          setpf(Profitloss2 - brokerage_total);
          setBtotal(brokerage_total);
        }
      } else {
        setask(msg.LTP);
        if (item.buy_sell == 1) {
          let Profitloss2 = 0;
          let brokerage_total = 0;
          if (parseFloat(msg.LTP) > 0) {
            Profitloss2 = (item.rate - msg.LTP) * item.qty;

            if (item.type == "fut") {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else if (item.type == "mcx") {
              if (item.brokerage < 10) {
                brokerage_total = parseFloat(
                  (item.brokerage * item.qty * parseFloat(msg.LTP)) / 100
                );
                brokerage_total = brokerage_total + item.brokerage_amt;
              } else {
                brokerage_total = item.brokerage_amt * 2;
              }
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            Profitloss2 = 0;
          }

          setpf(Profitloss2 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }
  };
  const socketbidask = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        setbid(msg.Bid);
        if (item.buy_sell == 0) {
          let Profitloss3 = 0;
          let brokerage_total = 0;
          Profitloss3 = (msg.Bid - item.rate) * item.qty;
          if (item.type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.type == "mcx") {
            if (item.brokerage < 10) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Bid)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }

          setpf(Profitloss3 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
      if (ask !== msg.Ask) {
        setask(msg.Ask);
        if (item.buy_sell == 1) {
          let Profitloss4 = 0;
          let brokerage_total = 0;
          Profitloss4 = (item.rate - msg.Ask) * item.qty;
          if (item.type == "fut") {
            brokerage_total = parseFloat(
              (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
            );
            brokerage_total = brokerage_total + item.brokerage_amt;
          } else if (item.type == "mcx") {
            if (item.brokerage < 10) {
              brokerage_total = parseFloat(
                (item.brokerage * item.qty * parseFloat(msg.Ask)) / 100
              );
              brokerage_total = brokerage_total + item.brokerage_amt;
            } else {
              brokerage_total = item.brokerage_amt * 2;
            }
          } else {
            brokerage_total = item.brokerage_amt * 2;
          }

          setpf(Profitloss4 - brokerage_total);
          setBtotal(brokerage_total);
        }
      }
    }
  };

  useEffect(() => {
    socket.emit("giverate", item.symbol_id);
    socket.on("trade" + item.symbol_id, sockettrade);
    socket.on("bidask" + item.symbol_id, socketbidask);
    return () => {
      socket.off("trade" + item.symbol_id, sockettrade);
      socket.off("bidask" + item.symbol_id, socketbidask);
    };
  }, []);

  const SqOff = () => {
    setShowSq(false);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
      client_id: item.client_id,
      standing_id: item.id,
      tpassword: tpassword,
    };

    backend.master_sq(data).then((r) => {
      if (r.error == "False") {
        toast.success(r.message, {
          backgroundColor: Dark.buy,
          color: Dark.text,
        });
        props.load();
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  return (
    <div
      style={{
        height: 50,
        display: "flex",
        backgroundColor: Dark.background,
        justifyContent: "center",
        alignItems: "center",
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
        textTransform: "uppercase",
      }}
    >
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.master_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.client_username}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1.5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 12,
          flexDirection: "column",
          fontSize: 16,
        }}
      >
        <div>{item.symbol}</div>
        <div style={{ fontSize: 10, paddingTop: 5 }}>
          {moment(item.expiry_date).format("DD-MMM")}
        </div>
      </div>
      <div
        style={{
          color: item.buy_sell == 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.buy_sell == 0 ? "BUY" : "SELL"}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.qty}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.rate}
      </div>
      <div
        style={{
          color: Dark.text,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {item.buy_sell == 0
          ? parseFloat(bid).toFixed(2)
          : parseFloat(ask).toFixed(2)}
      </div>
      <div
        style={{
          color: pf > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(pf + btotal).toFixed(0)}
      </div>
      <div
        style={{
          color: Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(btotal).toFixed(0)}({item.brokerage})
      </div>
      <div
        style={{
          color: pf > 0 ? Dark.buy : Dark.sell,
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        {parseFloat(pf).toFixed(0)}
      </div>
      <div
        onClick={() => setShowSq(true)}
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: 16,
        }}
      >
        <div
          style={{
            padding: 10,
            backgroundColor: Dark.sell,
            color: Dark.text,
            borderRadius: 10,
          }}
        >
          SQ OFF
        </div>
      </div>

      <Dialog
        visible={showsq}
        showHeader={false}
        className="modal"
        style={{ width: "40vw", height: "35vh" }}
        onHide={() => setShowSq(false)}
      >
        <TPassword
          Update={SqOff}
          tpassword={(a) => setTPassword(a)}
          text={"SQ OFF"}
        />
      </Dialog>
    </div>
  );
}
