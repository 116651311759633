import React, { useState } from "react";
import { Dark } from "../../../../Theme/Theme";
import { Dialog } from "primereact/dialog";
import Master from "./Master/Master";
import Client from "./Client/Client";
import Symbol from "./Symbol/Symbol";
import Filter from "./Filter/Filter";
import moment from "moment";
import { BsFilePdf } from "react-icons/bs";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PendingPdf from "./PendingPdf/PendingPdf";

export default function TopCard(props) {
  const [master, setMaster] = useState(false);
  const [client, setClient] = useState(false);
  const [symbol, setSymbol] = useState(false);
  const [filter, setFilter] = useState(false);

  const [filtertype, setFilterType] = useState("");

  const [master_data, setMasterData] = useState("");
  const [client_data, setClientData] = useState("");
  const [symbol_data, setSymbolData] = useState("");

  const Clear = () => {
    setMasterData("");
    setClientData("");
    setSymbolData("");
    setFilterType("");
    props.master("");
    props.client("");
    props.symbol("");
    props.filter("");
    props.reload();
  };

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: Dark.background,
        padding: 10,
        justifyContent: "center",
        alignItems: "center",
        textTransform:"uppercase"
      }}
    >
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>LIMIT</div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {props.limit}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>PENDING</div>
          <div
            style={{
              color: Dark.primary,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {props.total}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>STOP-LOSS</div>
          <div
            style={{
              color: Dark.sell,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {props.sl}
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => setMaster(true)}
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Master</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {master_data == "" ? "No Select" : master_data.username}
          </div>
        </div>

        <div
          onClick={() => setClient(true)}
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Client</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {client_data == "" ? "No Select" : client_data.username}
          </div>
        </div>

        <div
          onClick={() => setSymbol(true)}
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Symbol</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 10,
              fontSize: 16,
            }}
          >
            {symbol_data == "" ? "No Select" : symbol_data}
          </div>
        </div>

        <div
          onClick={() => setFilter(true)}
          style={{
            height: "60px",
            width: "120px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
            textAlign: "center",
          }}
        >
          <div style={{ color: Dark.text, fontSize: 12 }}>Filter</div>
          <div
            style={{
              color: Dark.text,
              marginTop: 5,
              fontSize: 16,
            }}
          >
            {filtertype == "" || filtertype == "CUSTOM" ? (
              <>
                <div style={{ marginBottom: 3, fontSize: 12 }}>
                  START : {moment(props.start).format("DD-MM-YYYY")}
                </div>
                <div style={{ fontSize: 12 }}>
                  END :{moment(props.end).format("DD-MM-YYYY")}
                </div>
              </>
            ) : (
              filtertype
            )}
          </div>
        </div>

        <div
          style={{
            height: "60px",
            width: "50px",
            backgroundColor: Dark.secondary,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            borderRadius: 10,
            marginRight: 10,
          }}
        >
          <div
            style={{
              color: Dark.text,
            }}
          >
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={
                <PendingPdf
                  pending={props.pending}
                  limit={props.limit}
                  sl={props.sl}
                  total={props.total}
                />
              }
              fileName={"Pending"}
            >
              {({ blob, url, loading, error }) => (
                <div style={{ color: Dark.text }}>
                  <BsFilePdf size={30} color={Dark.text} />
                </div>
              )}
            </PDFDownloadLink>
          </div>
        </div>
      </div>

      <Dialog
        header={"Master List"}
        visible={master}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setMaster(false)}
      >
        <Master
          start={props.start}
          end={props.end}
          symbol_id={props.symbol_id}
          client_id={props.client_id}
          master={props.master}
          updatemaster={(a) => setMasterData(a)}
          close={() => setMaster(false)}
        />
      </Dialog>

      <Dialog
        header={"Client List"}
        visible={client}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setClient(false)}
      >
        <Client
          start={props.start}
          end={props.end}
          symbol_id={props.symbol_id}
          master_id={props.master_id}
          client={props.client}
          updateclient={(a) => setClientData(a)}
          close={() => setClient(false)}
        />
      </Dialog>

      <Dialog
        header={"Symbol List"}
        visible={symbol}
        style={{ width: "50vw", height: "70vh" }}
        onHide={() => setSymbol(false)}
      >
        <Symbol
          start={props.start}
          end={props.end}
          filter={props.filter_select}
          client={props.client_select}
          symbol={props.symbol}
          updatesymbol={(a) => setSymbolData(a)}
          close={() => setSymbol(false)}
        />
      </Dialog>

      <Dialog
        header={"Filter List"}
        visible={filter}
        style={{ width: "40vw", height: "60vh" }}
        onHide={() => setFilter(false)}
      >
        <Filter
          start={props.start}
          end={props.end}
          filter={props.filter}
          updatefilter={(a) => {
            setFilterType(a);
            setFilter(false);
          }}
          close={() => setFilter(false)}
        />
      </Dialog>
    </div>
  );
}
